import * as Constantes from '../../../shared/constants';

import { IUsuario } from './../../../interfaces/usuario';
import { RepartosService } from './../../../services/datos/repartos.service';

// Módulos
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

// Interfaces y Modelos
import { ICampanya } from 'src/app/interfaces/campanya';
import { ICliente } from 'src/app/interfaces/cliente';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IPedido } from 'src/app/interfaces/pedido';
import { MCampanyaEstadosList, MCampanyaPreparacionEstadosList } from 'src/app/models/estadosList';

// Servicios
import { CampanyasService } from 'src/app/services/datos/campanyas.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { PedidosService } from './../../../services/datos/pedidos.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { ModalFactSalidaService } from 'src/app/services/modal-fact-salida.service';

// Librerías
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';



interface ILineas {
  uic: string;
  ean: string;
  sku: string;
  descripcion: string;
  cantidad: number;
  selectOptions: {
    value: string, label: string
  }[];

}

@Component({
  selector: 'app-campanyas-list',
  templateUrl: './campanyas-list.component.html',
  styleUrls: ['./campanyas-list.component.scss']
})
export class CampanyasListComponent implements OnInit {

  // Usar las constantes en el template
  Constantes = Constantes

  public lista: ICampanya[] = [];
  public clientesList: ICliente[] = [];
  public estadosList: any[] = MCampanyaEstadosList;
  public estadosPreparacionList: any[] = MCampanyaPreparacionEstadosList;

  selected: any = [];
  SelectionType = SelectionType;

  // Detalle de una campaña
  public campSel: ICampanya = {};
  public pedidosList: IPedido[] = [];
  public sumaTotArticulos: number = 0;
  public sumaTotReferencias: number = 0;

  // Detalle de un pedido 
  public pedidoDetall: IPedido = {};

  public rows: any = [];
  public temp: any[] = [];

  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  loading: boolean = false;
  loadingAlbaran: boolean = false;

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  opcionesList: IListaOpciones = {}
  cliSeleccionado: string = '';
  estadoSeleccionado: string = '';
  estadoPreparacionSeleccionado: string = '';
  pedidosSearch: string = '';

  // Control de cuando se cierra el modal
  modalClosedSubscription: Subscription | undefined;


  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: `total`
  }



  ColumnMode = ColumnMode;
  @ViewChild(CampanyasListComponent) table: CampanyasListComponent | any;

  constructor(
    public usuarioSrv: UsuarioService,
    private campanyaSrv: CampanyasService,
    private pedidosSrv: PedidosService,
    private RepartosSrv: RepartosService,
    private clientesSrv: ClientesService,
    private notificacionesSrv: NotificacionesService,
    public modalFactSalidaSrv: ModalFactSalidaService,

  ) { }

  ngOnInit(): void {
    this.cargaClientes();
    this.cargaLista();

    this.modalClosedSubscription = this.modalFactSalidaSrv.modalClosed.subscribe(() => {
      // Suscribirse a modalClosed para saber cuando se cierra el modal y actualizar los datos de los pedidos
      console.log('El modal se ha cerrado con el pedido:');
      this.cargaPedidosList();
    });
  }

  cargaLista() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.estado = parseInt(this.estadoSeleccionado);
    this.opcionesList.estadoPreparacion = parseInt(this.estadoPreparacionSeleccionado);

    this.loading = true;
    this.campanyaSrv.getListaFiltrada(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        this.lista = resp.data;

        console.log(' __cargaLista()', this.lista)

        this.rows = this.lista;
        this.temp = [...this.lista];
      })
  }

  cargaClientes() {
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })
  }



  cargaPedidosList() {
    this.opcionesList.id = undefined;
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.id_campanya = this.campSel.id;

    this.loading = true;
    this.pedidosSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        this.pedidosList = resp.data;

        this.sumaTotArticulos = this.pedidosList.reduce((total, pedido) => total + pedido.tot_articulos!, 0);
        this.sumaTotReferencias = this.pedidosList.reduce((total, pedido) => total + pedido.tot_referencias!, 0);

        console.log('--> this.pedidosList =', this.pedidosList)
        console.log('sumaTotReferencias =', this.sumaTotReferencias)
      })
  }




  // Filtros

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.cargaLista();
    this.campSel = {};
    this.pedidosList = [];
    this.pedidoDetall = {};
  }

  onEstadoSelect(id: string) {
    this.estadoSeleccionado = id;
    this.cargaLista();
    this.campSel = {};
    this.pedidosList = [];
    this.pedidoDetall = {};
  }

  onEstadoPreparacionSelect(id: string) {
    this.estadoPreparacionSeleccionado = id;
    this.cargaLista();
    this.campSel = {};
    this.pedidosList = [];
    this.pedidoDetall = {};
  }





  // Buscar campañas con un Pedido concreto
  buscarPedido(event: any) {
    const txt = event?.target?.value?.toUpperCase() ?? '';
    if (txt.length < 3) {
      this.notificacionesSrv.aviso('warning', 'Mínimo 3 caracteres');
      return;
    }

    this.loading = true;
    this.campanyaSrv.campanyasListConPedido(this.pedidosSearch)
      .subscribe(resp => {
        this.loading = false;

        console.log(' __buscarPedido cargaLista()', this.lista)

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return
        }

        this.notificacionesSrv.aviso('success', resp.mensaje);

        // Restablece los otros inputs
        this.cliSeleccionado = '';
        this.estadoSeleccionado = '';

        this.pedidosList = [];
        this.pedidoDetall = {};

        // carga datos de campañas en el dataTabe
        this.lista = resp.data;

        this.rows = this.lista;
        this.temp = [...this.lista];
      })

  }

  // Volver a cargar lista de campañas
  limpiaBuscaPedido() {
    this.pedidosSearch = '';
    this.cargaLista();
  }



  //  ** CRUD ** // 

  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar campaña?',
      icon: 'question',
      html: `Eliminar <b>${row.camp_cod}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.campanyaSrv.delete(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Campaña ${row.camp_cod} eliminada`);
              this.rows = this.rows.filter((objeto: any) => objeto.id !== row.id);
              if (!this.rows.length) this.pedidosList = [];
              this.pedidosList = [];
              this.pedidoDetall = {};
            }
          })
      }
    })
  }



  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr);
  }


  /// Búsqueda Global ///

  buscarTodo() {
    const txt = this.strBuscarTodo?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }

  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }

  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscarTodo();
  }


  //// AUX  ////

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }




  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');
  }


  // ** Selección de líneas  ** //

  onActivate(event: any) {
    if (event.type === 'click') {
      alert('onActivate')
      this.campSel = event.row;
      console.log('__Evento click  this.campSel', this.campSel);
      this.cargaPedidosList();
    }
  }


  infoClickCampanya(row: any) {
    // Info sobre una campaña

    this.campSel = row;
    console.log('__infoClickCampanya click  this.campSel', this.campSel);

    this.pedidosList = [];
    this.pedidoDetall = {};
    this.cargaPedidosList();
  }


  infoClickPedido(id_pedido: number) {
    // Info sobre un pedido

    this.opcionesList.id = id_pedido;

    this.loading = true;
    this.pedidosSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje || 'Error desconocido');
          return
        }
        this.pedidoDetall = resp.data[0];
        console.log('__infoClickPedido() pedidoDetall=', this.pedidoDetall);
      })
  }

  descargaAlbaranClick(id_pedido: number) {
    console.log('__descargaAlbaranClick() id_pedido=', id_pedido);
    this.loading = true;
    this.pedidosSrv.downloadAlbaran(id_pedido)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje || 'Error desconocido');
          return
        }
        const urlPdf: string = environment.pdfDir + '/' + resp.data;
        window.open(urlPdf, '_blank');
      })
  }


  // Acciones especiales sobre el pedido  //

  accionEspecialPedidoClick(id_pedido: number) {
    // Acciones especiales sobre un pedido

    this.infoClickPedido(id_pedido);  // Mostrar el detalle del pedido

    const inputOptions: { [key: string]: string } = {
      '1': 'No va a salir'
    };
    if (this.campSel.campanyaTipo === 'Normal') inputOptions['2'] = 'Va a salir como está';
    inputOptions['3'] = 'Avisar al cliente';

    Swal.fire({
      title: 'Acción sobre el pedido',
      html: `¿Qué hacer con el pedido?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Seleccionar',
      input: 'select',
      inputPlaceholder: 'Selecciona una opción', // Establece el valor por defecto  
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return 'Debes seleccionar qué pasa con el pedido.';
        }
        return null; // Devolver null si la validación pasa
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value == 1) this.pedidoNoSale(this.pedidoDetall);
        if (result.value == 2) this.pedidoSaleParcial(this.pedidoDetall);
        if (result.value == 3) this.pedidoAvisarCliente(this.pedidoDetall);
      }
    });
  }


  pedidoAvisarCliente(pedido: IPedido) {
    Swal.fire({
      title: 'Avisar al cliente',
      icon: 'info',
      html: `<b>¿Avisar al cliente sobre su pedido ${pedido.pedido_cli}?</b><br> 
      <small>
        <ul>
          <li>Se informará a ${pedido.cliNombre} sobre su pedido ${pedido.pedido_cli}.</li>
          <li>A partir de este momento, el Cliente tendrá activada la opción de <span class="text-info"><i>solicitar acciones especiales</i></span> para este pedido desde la Intranet. Siempre que el pedido siga en estado 'Pendiente'</li> 
        </ul>
      </small>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si, Mandar aviso',
    }).then((result) => {
      if (result.isConfirmed) {

        // Modificar registro del pedido y añadir un registro de aviso
        this.loading = true;
        this.pedidosSrv.marcarAviso(pedido.id!)
          .subscribe(resp => {
            this.loading = false;
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return
            }
            this.cargaPedidosList();            // Actualizar lista de pedidos 
            this.infoClickPedido(pedido.id!);    // Mostrar el detalle del pedido
          })
      }
    })

  }



  campanyaAvisarCliente(row: any) {
    Swal.fire({
      title: 'Avisar al cliente',
      icon: 'info',
      html: `<b>¿Avisar al cliente ${row.cliNombre} sobre TODOS sus pedidos pendientes de esta campaña?</b><br> 
      <small>
        <ul> 
          <li>A partir de este momento, el Cliente tendrá activada la opción de <span class="text-info"><i>solicitar acciones especiales</i></span> para estos pedidos desde la Intranet. Siempre que el pedido siga en estado 'Pendiente'</li> 
        </ul>
      </small>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si, Mandar aviso',
    }).then((result) => {
      if (result.isConfirmed) {
        // Modificar los registros de los pedidos y añadir un registro de aviso de campaña
        this.loading = true;
        this.pedidosSrv.marcarAvisoCampanya(row!)
          .subscribe(resp => {
            console.log('__campanyaAvisarCliente()', resp)
            this.loading = false;
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return
            }
            this.notificacionesSrv.aviso('success', resp.mensaje);

            this.cargaPedidosList();            // Actualizar lista de pedidos 
            this.infoClickCampanya(row);    // Mostrar el detalle del pedido
          })
      }
    })


  }


  pedidoNoSale(pedido: IPedido) {
    console.log('__pedidoNoSale ', pedido)
    // El pedido NO va a salir, confirmar
    const id_pedido = pedido.id!;

    Swal.fire({
      title: 'Cancelar pedido',
      icon: 'warning',
      html: `<b>¿Quieres cancelar este pedido?</b><br> 
      <small>
        <ul>
          <li>Se generarán transacciones para descontar la mercancía de los artículos del pedido.</li>
          <li>Se prepara una entrada en stock con los artículos de ese pedido (están en el/los carros o en el cajetín)</li>
          <li>Se marca el estado del pedido como <i>Anulado</i> </li> 
        </ul>
      </small>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si, Cancelar pedido',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pedidoNoSaleConfirm(id_pedido);
      }
    })

  }


  pedidoNoSaleConfirm(id_pedido: number) {
    // El pedido NO va a salir, condfirmado

    let apiData: any = {};

    this.loading = true;
    const userdata: IUsuario = this.usuarioSrv.userdata;

    apiData = { ...apiData, id_pedido, userdata };
    console.table('__apiData', apiData)

    this.RepartosSrv.pedidoNoSale(apiData)
      .subscribe(resp => {
        this.loading = false;
        console.log('El pedido NO va a salir:', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
        }
        this.notificacionesSrv.aviso('success', resp.mensaje);

        this.cargaPedidosList();            // Actualizar lista de pedidos para mostrar cambios de estado
        this.infoClickPedido(id_pedido);    // Mostrar el detalle del pedido
      });
  }



  pedidoSaleParcial(pedido: IPedido) {
    // El pedido va a salir como está
    const id_pedido = pedido.id!;

    let lineas: ILineas[] = [];
    let apiData: any = {};

    this.loading = true;
    this.RepartosSrv.getPedidoArticulosPendientes(id_pedido)
      .subscribe(async resp => {
        this.loading = false;
        console.log('__getPedidoArticulosPendientes:', resp);
        apiData = resp.data;
        const pedido_cli: string = resp.data.pedido.pedido_cli;

        lineas = resp.data.repartos.map((reparto: any) => {
          return {
            uic: reparto.uic,
            ean: reparto.articulo.ean,
            sku: reparto.articulo.sku,
            descripcion: reparto.articulo.descripcion,
            foto: reparto.articulo.foto,
            cantidad: (reparto.cant_repartir - reparto.cant_repartida),
            selectOptions: [
              { value: 'Rotura', label: 'Rotura' },
              { value: 'Extraviado', label: 'Extraviado' },
              { value: 'Anulado', label: 'Anulado' },
            ],
          };

        });

        if (!lineas.length) {
          //  this.notificacionesSrv.aviso('error', 'Este pedido NO tiene ningún artículo a repartir');
          //   return;
        }

        const datosPedido = {
          pedido_cli: pedido_cli,
          lineas: lineas,
        };

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return;
        }
        this.notificacionesSrv.aviso('success', resp.mensaje);

        const formValues = await this.mostrarFormularioDinamico(datosPedido);

        if (formValues) {
          const userdata: IUsuario = this.usuarioSrv.userdata;
          apiData = { ...apiData, formValues, userdata };
          console.table('__apiData', apiData)

          this.loading = true;
          this.loadingAlbaran = true;

          this.RepartosSrv.pedidoSaleParcial(apiData)
            .subscribe(resp => {

              this.loading = false;
              this.loadingAlbaran = false;

              console.log('__pedidoSaleParcial', resp)

              if (resp.error) {
                this.notificacionesSrv.aviso('error', resp.mensaje);
                return
              }
              this.notificacionesSrv.aviso('info', `Generadas ${resp.totales.conta.transacciones} transacciones y ${resp.totales.conta.entradas} entradas en stock`);
              this.notificacionesSrv.aviso('success', resp.mensaje);

              this.cargaPedidosList();            // Actualizar lista de pedidos para mostrar cambios de estado
              this.infoClickPedido(id_pedido);    // Mostrar el detalle del pedido
            });
        }

        return
      });

  }



  async mostrarFormularioDinamico(datosPedido: any) {
    const inputs = datosPedido.lineas;

    const tableRowsHTML = inputs
      .map((input: ILineas, index: any) => {
        const selectOptionsHTML = input.selectOptions.map(option => `<option value="${option.value}">${option.label}</option>`).join('');

        return `
          <tr>
            <td style="white-space: nowrap;">${input.uic}</td>           
            <td style="white-space: nowrap;">${input.ean}</td>
            <td style="white-space: nowrap;">${input.sku}</td>
            <td class="small">${this.limitarYAgregarPuntos(input.descripcion, 80)}</td>            
            <td>${input.cantidad}</td>
            <td>
              <select id="swal-select${index}" class="form-control">
                ${selectOptionsHTML}
              </select>
            </td>
          </tr>
        `;
      })
      .join('');

    let subTituloHTML = `Estos artículos NO se van a incluir en el envío del pedido <b>${datosPedido.pedido_cli}</b>`
    subTituloHTML += `<p style="font-size: small"><b><span style="color: red;">IMPORTANTE:</span></b> Debes asegurarte de que los carros de la lista no se están utilizando en este momento.</p>`

    const tableHTML = `
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th style="white-space: nowrap;">UIC</th>
            <th style="white-space: nowrap;">EAN</th>
            <th style="white-space: nowrap;">SKU</th>
            <th>Descripción</th>
            <th style="white-space: nowrap;">C.Pendiente</th>
            <th>Motivo</th>
          </tr>
        </thead>
        <tbody>
          ${tableRowsHTML}
        </tbody>
      </table>
    `;
    const avisosHTML = `
    <span style="font-size: small"> 
        <p>Para los artículos que tienen uic se creará una transacción de recuento por el motivo indicado en cada línea.<br>
       Asegurate de que los artículos sin UIC tiene falta de stock</p> 
    </span>`

    const { value: formValues } = await Swal.fire({
      title: 'El pedido sale como está',
      width: '80%',
      html: subTituloHTML + tableHTML + avisosHTML,
      footer: 'Se va a crear el albarán y el archivo csv de transporte ',

      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',

      focusConfirm: false,
      preConfirm: () => {
        return inputs.map((input: { uic: string; ean: string; sku: string; }, index: number) => {
          const selectElement = document.getElementById(`swal-select${index}`) as HTMLSelectElement | null;
          const selectValue = selectElement?.value || '';

          return { uic: input.uic, ean: input.ean, sku: input.sku, selectValue };
        });
      }
    });

    // if (formValues) {
    //   Swal.fire(JSON.stringify(formValues));
    // } else {
    //   alert('Sin valores en formValues');
    // }

    return (formValues);
  }




  abrirModal(pedido: IPedido) {
    this.modalFactSalidaSrv.pedido = pedido
    this.pedidoDetall = pedido
    this.modalFactSalidaSrv.abrirModal();
  }




  // AUX  //

  limitarYAgregarPuntos(value: string, maxLength: number): string {
    // Acorta un string a maxLength caracteres
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    } else {
      return value;
    }
  }



}



