<div *ngIf="loadingAlbaran"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Generando albarán...</h3>
</div>



<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-3">

                    <div class="col-4">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <!-- Seleccionar todo -->
                    <div class="col-md-2">
                        <div class="demo-checkbox"
                             *ngIf="campanyaInfo.campanyaTipo === 'Normal'">
                            <input id="chkMenu"
                                   type="checkbox"
                                   class="filled-in"
                                   [(ngModel)]="selectAllRows"
                                   (ngModelChange)="toggleSelectAllRows()" />
                            <label for="chkMenu">Seleccionar todo </label>
                        </div>
                    </div>

                    <!-- Seleccionar NO asignados -->
                    <div class="col-md-2">
                        <div class="demo-checkbox"
                             *ngIf="campanyaInfo.campanyaTipo === 'Normal'">
                            <input id="selectNoAssigned"
                                   type="checkbox"
                                   class="filled-in"
                                   [(ngModel)]="selectNoAssigned"
                                   (ngModelChange)="toggleNoAssigned()" />
                            <label for="selectNoAssigned">NO asignados </label>
                        </div>
                    </div>


                    <!-- Imprimir etiquetas -->
                    <div class="col-md-2"
                         *ngIf="campanyaInfo.campanyaTipo === 'Normal'">
                        <button type="button"
                                (click)="pdfEtiquetaMulti()"
                                class="btn btn-outline-info"
                                title="Imprimir etiquetas caja 1">
                            Imprimir etiquetas caja 1
                            <i class="fa-solid fa-print"></i>
                        </button>
                    </div>



                    <!--Enviar Pedido (pedido 1 a 1 y NO servido)-->
                    <div class="col-md-2"
                         *ngIf="campanyaInfo.campanyaTipo === '1 a 1' && pedidoCero.estado === '1'">
                        <button type="button"
                                (click)="enviarPedidoClick()"
                                class="btn btn-outline-warning"
                                title="Enviar Pedido">
                            Enviar Pedido
                            <i class="fa-solid fa-truck-arrow-right"></i>
                        </button>
                    </div>


                    <div class="col-md-2"> <!-- lineas + export excel -->
                        <form>

                            <div class="row float-right">

                                <div class="col-md-7">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="9999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <button type="button"
                                                    (click)="refreshData()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Refrescar Datos">
                                                <i class="fa-solid fa-refresh"></i>
                                            </button>
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <small>Selecciona carros para la asignación de usuarios. Para preparación de pedidos de la campaña <b>{{campanyaInfo.camp_cod}}</b>
                        <span *ngIf="campanyaInfo.campanyaTipo === '1 a 1'"
                              class="text-warning ml-1"><i>Campaña tipo pedido 1 a 1</i>
                            <img src="../../../assets/images/icon/1a1.png"
                                 height="20px"
                                 alt="1 a 1"
                                 class="d-inline-block ml-2">
                        </span>

                    </small>

                    <!-- datatable -->
                    <ngx-datatable class="bootstrap"
                                   [ngClass]="{'pointer': campanyaInfo.campanyaTipo !== '1 a 1'}"
                                   [rows]="rows"
                                   [columnMode]="ColumnMode.flex"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [messages]="messages"
                                   rowHeight="auto"
                                   [limit]=rowsLimit
                                   [selected]="selected"
                                   [selectionType]="SelectionType.multiClick"
                                   (activate)="onActivate($event)"
                                   [summaryRow]="true"
                                   [summaryHeight]="50"
                                   [summaryPosition]="summaryPosition">



                        <!-- id -->
                        <ngx-datatable-column name="Id"
                                              prop='id'
                                              [flexGrow]="70"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- recogida_cod -->
                        <ngx-datatable-column name="Cód.preparación"
                                              prop='recogida_cod'
                                              [flexGrow]="110"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- estado Preparación-->
                        <ngx-datatable-column name="Estado"
                                              prop='estado_preparacion'
                                              [flexGrow]="100"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div *ngIf="row.recogida_cod"
                                     [innerHTML]="value | preparacionEstado"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- id_articulo -->
                        <ngx-datatable-column name="id_articulo"
                                              prop='id_articulo'
                                              [flexGrow]="70"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- foto -->
                        <ngx-datatable-column name=""
                                              prop='foto'
                                              [flexGrow]="50"
                                              [resizeable]="false"
                                              [sortable]="false">
                            <ng-template ngx-datatable-cell-template
                                         let-row="row"
                                         let-value="value">
                                <div *ngIf="value">
                                    <img [src]="fotoDir +'/articulos' + imgResized + value"
                                         loading="lazy"
                                         alt="Foto"
                                         class="rounded pointer border"
                                         height="25px"
                                         title="foto">
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- sku -->
                        <ngx-datatable-column name="Sku"
                                              prop='sku'
                                              [flexGrow]="150"
                                              [summaryFunc]="cuentaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [ngClass]="{ 'text-warning': row.estado === 1 }">
                                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- ean -->
                        <ngx-datatable-column name="Ean"
                                              prop='ean'
                                              [flexGrow]="150">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- descripcion -->
                        <ngx-datatable-column name="Descripción"
                                              prop='descripcion'
                                              [maxWidth]="200"
                                              [flexGrow]="200">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(limitarYAgregarPuntos(value, 20), textoDestacado)"
                                     [title]="value"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- uic -->
                        <ngx-datatable-column name="UIC"
                                              prop='uic'
                                              [flexGrow]="130">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div class="monospace"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- estado_uic -->
                        <ngx-datatable-column name="uic"
                                              prop='estado_uic'
                                              [flexGrow]="100"
                                              [resizeable]="false"
                                              [sortable]="false">
                            <ng-template ngx-datatable-header-template>
                                <div>
                                    <i class="fas"
                                       [ngClass]="'fa-solid fa-dolly'"></i>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div *ngIf="row.uic"
                                     [innerHTML]="value | uicLabelEstado"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- username -->
                        <ngx-datatable-column name="Recogido"
                                              prop='username'
                                              [flexGrow]="100">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- username_uic -->
                        <ngx-datatable-column name="Asignado"
                                              prop='username_uic'
                                              [flexGrow]="100">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- cant_reserva -->
                        <ngx-datatable-column name="Cantidad"
                                              prop='cant_reserva'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cant_recogida -->
                        <ngx-datatable-column name="Recogido"
                                              prop='cant_recogida'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{
                                        'bg-blue rounded':  row.estado === '2',
                                        'bg-green rounded': row.estado === '3'
                                     }"
                                     class="pr-2"
                                     [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- cant_inicial -->
                        <!--                             
                        <ngx-datatable-column name="Inicial"
                                              prop='cant_inicial'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{ 'bg-warning rounded':  row.estado === '8' }"
                                     class="pr-2"
                                     [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>
                        -->






                        <!-- saltar -->
                        <!-- <ngx-datatable-column name="Incidencia"
                                              prop='saltar'
                                              [maxWidth]="150"
                                              [flexGrow]="150">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(limitarYAgregarPuntos(value, 20), textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column> -->

                        <!-- cant_repartida -->
                        <ngx-datatable-column name="Preparado"
                                              prop='cant_repartida'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{
                                    'bg-green rounded': (value == row.cant_recogida) && value > 0,
                                    'bg-blue rounded': (value < row.cant_recogida) && value > 0,
                                    'bg-red rounded': value > row.cant_recogida
                                 }"
                                     class="pr-2"
                                     [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>





                    </ngx-datatable>
                </div>


                <div class="card border border-dark rounded mt-4 p-1">
                    <div class="row">

                        <div class="col-md-6">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <p class="card-text">
                                        <span class="h3">{{clienteInfo.nombre}}</span> <br>
                                        Campaña: <b>{{campanyaInfo.camp_cod}} </b><span class="text-success">{{campanyaInfo.descripcion}} </span> <br>
                                        <span *ngIf="campanyaInfo.campanyaTipo === '1 a 1'"
                                              class="text-warning ml-1"><i>Campaña tipo pedido 1 a 1</i><br></span>
                                        Fecha de entrada: {{campanyaInfo.fecha_entrada}}
                                    </p>
                                    <hr>
                                    <p class="card-text">
                                        Total pedidos: <b>{{campanyaInfo.tot_pedidos | number: '0.0-0':'es'}} </b><br>
                                        Total artículos: <b>{{campanyaInfo.tot_articulos | number: '0.0-0':'es'}} </b><br>
                                        Total referencias: <b>{{campanyaInfo.tot_referencias | number: '0.0-0':'es'}} </b><br>
                                    </p>
                                </div>
                            </div>
                        </div>





                        <div *ngIf="selected.length"
                             class="col-md-6">


                            <!-- form de detall -->
                            <form class="form"
                                  [formGroup]="campForm"
                                  (ngSubmit)="enviar()"
                                  class="form-horizontal">


                                <div>
                                    <div class="form-body">

                                        <div class="card bg-light">
                                            <div class="card-body">

                                                <!-- id_usuario -->
                                                <div class="col-12">
                                                    <div class="form-group"
                                                         [ngClass]="{ 'has-danger': enviado && f['id_usuario'].errors }">
                                                        <label class="form-control-label">Asignar preparación de pedidos al usuario:</label>
                                                        <select formControlName="id_usuario"
                                                                class="form-control"
                                                                [ngClass]="{ 'form-control-danger': enviado && f['id_usuario'].errors }">
                                                            <option value=''>Selecciona...</option>
                                                            <option *ngFor="let usuario of userList"
                                                                    [value]="usuario.id">
                                                                <b>{{usuario.username}}</b> - {{usuario.nombre}}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="enviado && f['id_usuario'].errors"
                                                             class="form-control-feedback">
                                                            <div *ngIf="f['id_usuario'].errors['required']">Obligatorio</div>
                                                            <div *ngIf="campForm.get('id_usuario')?.errors?.['apiError']">{{
                                                                campForm.get('id_usuario')?.errors?.['apiError'] }}</div>
                                                        </div>
                                                    </div>

                                                    <hr>

                                                    <h4 class="text-info">Total UIC's seleccionados: {{totSelUics | number: '0.0-0':'es'}} </h4>
                                                    <p class="card-text">
                                                        Total registros seleccionados: {{selected.length | number: '0.0-0':'es'}} <br>
                                                        Total articulos seleccionados: {{totSelCantidad | number: '0.0-0':'es'}} <br>
                                                    </p>



                                                </div>

                                            </div> <!-- card-body -->

                                        </div>
                                    </div>


                                    <hr>

                                    <div class="d-flex">
                                        <button [disabled]="loading"
                                                type="button"
                                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                                (click)="cancelarSelecctedClick()">Cancelar</button>

                                        <button [disabled]="loading"
                                                type="submit"
                                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                            <i *ngIf="loading"
                                               class="fa fa-spinner fa-spin"></i>
                                            Enviar
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>



                        <div *ngIf="!selected.length"
                             class="col-md-6"> <button [disabled]="loading"
                                    type="button"
                                    class="btn btn-outline-info btn-rounded mt-2"
                                    [routerLink]="['/panel/preparacion_new/', id_campanya]">Ver Recogidas de {{campanyaInfo.camp_cod}}
                                <img *ngIf=" campanyaInfo.campanyaTipo === '1 a 1'"
                                     src="../../../assets/images/icon/1a1.png"
                                     height="25px"
                                     alt="1 a 1"
                                     class="d-inline-block ml-1 mb-1">
                            </button>
                            <br>
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn btn-outline-secondary btn-rounded mt-2"
                                    [routerLink]="'/panel/campanyas_lista'">Volver</button>
                        </div>


                    </div> <!-- row -->
                </div> <!-- card -->



            </div> <!-- card-body -->

        </div>
    </div>


</div>