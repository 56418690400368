<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-2">
                    <div class="col-3">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                            <select class="form-control"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <!-- [disabled]="selected.length"-->
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="demo-checkbox">
                            <input id="chkMenu"
                                   type="checkbox"
                                   class="filled-in"
                                   [(ngModel)]="selectAllRows"
                                   (ngModelChange)="toggleSelectAllRows()" />
                            <label for="chkMenu">Seleccionar todo </label>
                        </div>
                    </div>

                    <div class="col-3">
                        <form>

                            <div class="row float-right">

                                <div class="col-sm-8">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <button [disabled]="loading"
                                                    type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>




                <div class="rounded border">
                    <small>Selecciona los pedidos para la/s nueva/s campaña/s</small>

                    <!-- datatable -->
                    <ngx-datatable class="bootstrap pointer"
                                   [rows]="rows"
                                   [columnMode]="ColumnMode.flex"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [messages]="messages"
                                   rowHeight="auto"
                                   [limit]="rowsLimit"
                                   [selected]="selected"
                                   [selectionType]="SelectionType.multiClick"
                                   (activate)="onActivate($event)"
                                   [summaryRow]="true"
                                   [summaryHeight]="50"
                                   [summaryPosition]="summaryPosition">


                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              [flexGrow]="70">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- fecha_entrada -->
                        <ngx-datatable-column name="Fecha entrada"
                                              prop='fecha_entrada'
                                              [flexGrow]="130">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | date:'yyyy/MM/dd HH:mm', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cliNombre -->
                        <ngx-datatable-column name="Cliente"
                                              prop='cliNombre'
                                              [flexGrow]="130"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>

                                <span *ngIf="row.tipo==='M'"
                                      class="text-secondary ml-2"
                                      style="display: inline-block;"
                                      title="Creado manualmente por el cliente">
                                    <i class="fa-solid fa-network-wired"></i>
                                </span>

                            </ng-template>
                        </ngx-datatable-column>


                        <!-- pedido_cli -->
                        <ngx-datatable-column name="Pedido"
                                              prop='pedido_cli'
                                              [flexGrow]="150"
                                              [summaryFunc]="cuentaCeldas">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [ngClass]="{ 'text-warning arrow': row.estado.toString().startsWith('8') }">

                                    <span *ngIf="row.estado === 81"
                                          class="mr-1 text-warning"
                                          title="Contiene artículos no referenciados"
                                          style="display: inline-block;">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                    <span *ngIf="row.estado === 71"
                                          class="mr-1 text-info"
                                          title="Contiene artículos con STOCK insuficiente"
                                          style="display: inline-block;">
                                        <i class="fa-solid fa-circle-info"></i>
                                    </span>
                                    <span *ngIf="row.estado === 83"
                                          class="mr-1 text-danger"
                                          title="Este pedido NO tiene líneas de detalle"
                                          style="display: inline-block;">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                    <span *ngIf="row.estado === 84"
                                          class="mr-1 text-danger"
                                          title="Pedido sin referencia por parte del cliente"
                                          style="display: inline-block;">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                    <span *ngIf="row.estado === 85"
                                          class="mr-1 text-danger"
                                          title="Pedido de un cliente NO reconocido"
                                          style="display: inline-block;">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                    <span *ngIf="row.estado === 86"
                                          class="mr-1 text-danger"
                                          title="Pedido ya existente para este cliente"
                                          style="display: inline-block;">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                    </span>

                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"
                                          [class.font-weight-bold]="row.destacarLinea"></span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_articulos -->
                        <ngx-datatable-column name="Articulos"
                                              prop='tot_articulos'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_referencias -->
                        <ngx-datatable-column name="Refs."
                                              prop='tot_referencias'
                                              [flexGrow]="85"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- poblacion -->
                        <ngx-datatable-column name="Población"
                                              [flexGrow]="300">
                            <ng-template let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto( row.cp+'-'+row.poblacion, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- pais_iso -->
                        <ngx-datatable-column name="País"
                                              prop='pais_iso'
                                              [flexGrow]="85">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- contrarrembolso -->
                        <ngx-datatable-column name="Reemb."
                                              prop='contrarrembolso'
                                              [flexGrow]="90"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <ng-container *ngIf="value">
                                    {{ value | number: '0.0-2':'es'}} €
                                </ng-container>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- nombre -->
                        <ngx-datatable-column name="Destinatario"
                                              prop='nombre'
                                              [flexGrow]="200">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- Ver pedido-->
                        <ngx-datatable-column name=""
                                              [flexGrow]="60"
                                              [sortable]="false">
                            <ng-template let-row="row"
                                         ngx-datatable-cell-template>
                                <button [disabled]="loading"
                                        class="no-select btn btn-info btn-xs"
                                        ng-di
                                        (click)="infoPedido($event, row)">Info</button>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- Borrar pedido-->
                        <ngx-datatable-column name=""
                                              [flexGrow]="70"
                                              [sortable]="false">
                            <ng-template let-row="row"
                                         ngx-datatable-cell-template>
                                <button [disabled]="loading"
                                        class="no-select btn btn-danger btn-xs "
                                        (click)="delPedido($event, row)">Borrar</button>
                            </ng-template>
                        </ngx-datatable-column>


                    </ngx-datatable>
                </div>




                <div *ngIf="selected.length">


                    <!-- form de detall -->
                    <form class="form p-t-20"
                          [formGroup]="campForm"
                          (ngSubmit)="enviar()"
                          class="form-horizontal">


                        <div class="form-body">

                            <h4 class="card-title text-info"></h4>
                            <h6 class="card-subtitle"></h6>

                            <div class="card border border-dark rounded mt-4 p-1">
                                <div class="row">

                                    <!-- descripcion -->
                                    <div class="col-md-12">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['descripcion'].errors }">
                                            <label class="form-control-label">Descripción:</label>
                                            <input type="text"
                                                   formControlName="descripcion"
                                                   class="form-control"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['descripcion'].errors }">

                                            <div *ngIf="enviado && f['descripcion'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="f['descripcion'].errors['required']">Obligatorio</div>
                                                <div *ngIf="campForm.get('descripcion')?.errors?.['apiError']">
                                                    {{campForm.get('descripcion')?.errors?.['apiError'] }}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div> <!-- row -->

                            </div>
                        </div>


                        <hr>

                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/campanyas_lista'">Cancelar</button>


                            <button [disabled]="loading"
                                    *ngIf="selected.length===1"
                                    type="button"
                                    (click)="unoAunoClick()"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Crear pedido 1 a 1
                                <img src="../../../assets/images/icon/1a1.png"
                                     height="25px"
                                     alt="1 a 1"
                                     class="d-inline-block ms-2">
                            </button>

                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Crear {{cuentaClientes}} campaña{{ cuentaClientes !== 1 ? 's' : '' }}</button>

                        </div>

                    </form>
                </div>

            </div> <!-- card-body -->

        </div>
    </div>




    <!-- Alertas -->
    <div class="col-12">

        <div *ngIf="pedidoInfo.estado === 81"
             class="alert alert-warning"> <i class="fa-solid fa-triangle-exclamation"></i> <strong> Atención</strong>
            Este pedido contiene artículos no referenciados en el maestro de artículos para este cliente.
            <button [disabled]="loading"
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"> <span aria-hidden="true">×</span> </button>
        </div>
        <div *ngIf="pedidoInfo.estado === 71"
             class="alert alert-info"> <i class="fa-solid fa-triangle-exclamation"></i> <strong> Atención</strong>
            Este pedido contiene artículos con STOCK insuficiente para ser servido.
            <button [disabled]="loading"
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"> <span aria-hidden="true">×</span> </button>
        </div>
        <div *ngIf="pedidoInfo.estado === 83"
             class="alert alert-danger"> <i class="fa-solid fa-triangle-exclamation"></i> <strong> Atención</strong>
            No se ha podido encontrar ninguna línea de detalle para este pedido.
            <button [disabled]="loading"
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"> <span aria-hidden="true">×</span> </button>
        </div>
        <div *ngIf="pedidoInfo.estado === 84"
             class="alert alert-danger"> <i class="fa-solid fa-triangle-exclamation"></i> <strong> Atención</strong>
            Este pedido NO tiene referencia por parte del cliente.
            <button [disabled]="loading"
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"> <span aria-hidden="true">×</span> </button>
        </div>
        <div *ngIf="pedidoInfo.estado === 85"
             class="alert alert-danger"> <i class="fa-solid fa-triangle-exclamation"></i> <strong> Atención</strong>
            Este pedido es de un cliente NO reconocido.
            <button [disabled]="loading"
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"> <span aria-hidden="true">×</span> </button>
        </div>
        <div *ngIf="pedidoInfo.estado === 86"
             class="alert alert-danger"> <i class="fa-solid fa-triangle-exclamation"></i> <strong> Atención</strong>
            Ya existente un pedido anterior con esta referencia para este cliente
            <button [disabled]="loading"
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"> <span aria-hidden="true">×</span> </button>
        </div>

    </div>





    <!-- Tabla de detalle de la entrada seleccionada -->
    <div *ngIf="pedidosList.length"
         class="col-12">

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Detalle del Pedido <b>{{pedidoInfo.pedidoCli}}</b> de {{pedidoInfo.cliNombre}}</h4>
                <h6 class="card-subtitle">Artículos</h6>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Id</th>
                                    <th>Sku</th>
                                    <th>Ean</th>
                                    <th>Artículo</th>
                                    <th style="text-align: right; padding-right: 30px">Cantidad</th>
                                    <th style="text-align: right; padding-right: 30px">Stock</th>
                                    <th style="text-align: right; padding-right: 30px">Reservado</th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let lin of pedidosList; let i = index">
                                    <tr [ngClass]="{'text-danger': lin.estado && lin.estado.toString().startsWith('8') }">
                                        <td>
                                            <span *ngIf="lin.estado === 81">
                                                <a href="javascript: void(0);"
                                                   [routerLink]="'/panel/articulo_edit/E' + lin.id"
                                                   title="Crear artículo">
                                                    <i class="fa-solid fa-circle-plus"></i>
                                                </a>
                                            </span>
                                        </td>
                                        <td>
                                            <img [src]="fotoDir +'/articulos' + imgResized +  lin.artFoto"
                                                 loading="lazy"
                                                 class="rounded border"
                                                 height="25px">
                                        </td>
                                        <td>{{ lin.id_articulo }}</td>
                                        <td>{{ lin.artSku }}</td>
                                        <td>{{ lin.artEan }}</td>
                                        <td>{{ lin.artDescripcion }}</td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace"
                                                 [class.text-danger]="lin.cantidad > lin.artStock">
                                                {{lin.cantidad | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.artStock | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.stockRes | number: '0.0-0':'es'}}
                                            </div>
                                        </td>

                                    </tr>

                                </ng-container>
                            </tbody>

                        </table>
                    </div>


                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>



</div>